.pulse-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Center on full viewport height */
  }
  
  .pulse-bar {
    width: 8px;
    height: 40px;
    margin: 0 5px;
    background-color: #C39037; /* Adjust to match your theme color */
    animation: pulse-bar 1s infinite ease-in-out;
  }
  
  .pulse-bar:nth-child(1) {
    animation-delay: 0s;
  }
  
  .pulse-bar:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .pulse-bar:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes pulse-bar {
    0%, 40%, 100% {
      transform: scaleY(0.5);
    }
    20% {
      transform: scaleY(1);
    }
  }
  