h1,h2,h3,h4,h5,h6{
    font-weight: bold !important;
    padding: 10px 0px;
}
tr,th,td,tbody{
    border: 1px solid black !important;
}
td{
    padding: 2px 5px;
}
